import React, { PureComponent } from 'react';
import { Card, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import momentTZ from 'moment-timezone';
import { toast } from 'react-toastify';
import i18n from 'i18n';
import './index.scss';
import defaultIcon from 'assets/images/resource/icon.png';

export default class CardTable extends PureComponent {
  static propTypes = {
    tasks: PropTypes.array,
    selectedTasks: PropTypes.array,
    onSelectCard: PropTypes.func,
    ableToSelect: PropTypes.bool,
    userInfo: PropTypes.object
  };

  static defaultProps = {
    tasks: [],
    selectedTasks: [],
    onSelectCard: () => {},
    ableToSelect: false
  };

  constructor(props) {
    super(props);
    this.script = null;
    this.state = {
      loadTotal: 0,
      loadNum: 10,
      page: 0,
      tagScriptUrl: 't-tag.js?projectid=TPASSPORT-SSO-PORTAL&sendonload=true&maxlogs=15&epid=tci-pf'
    };
  }

  loadTagScript = (removeOldScript = true) => {
    // remove old script
    if (removeOldScript && this.script) {
      console.log('remove old t-tag script');
      document.body.removeChild(this.script);
    }
    // build new script
    this.script = document.createElement('script');
    this.script.src = this.state.tagScriptUrl;
    this.script.async = '';
    this.script.setAttribute('nonce', process.env.REACT_APP_RANDOME_NUMBER);
    document.body.appendChild(this.script);
  };

  componentDidMount() {
    this.loadTagScript();
  }

  componentWillUnmount() {
    document.body.removeChild(this.script);
  }

  onClick = item => {
    if (item.isMaintenance) {
      toast.warning(i18n.t('label.resource.isMaintenance'));
      return;
    }

    if (this.props.ableToSelect) {
      this.props.onSelectCard(item.id);
    } else {
      this.props.onClickCard(item);
    }
  };

  loadFunc = pageNumber => {
    const count = parseInt(this.state.loadNum * pageNumber);
    this.setState({ loadTotal: count, page: pageNumber });
    this.loadTagScript(true);
  };

  render() {
    const { tasks, userInfo } = this.props;
    const { loadTotal, page } = this.state;
    const lang = localStorage.getItem('sysLanguage') || 'en_US';
    const formatDate = lang === 'en_US' ? 'MM/DD/YYYY HH:mm' : 'YYYY/MM/DD HH:mm';
    return (
      // <InfiniteScroll
      //   pageStart={page}
      //   loadMore={this.loadFunc}
      //   hasMore={tasks.length > loadTotal}
      //   loader={
      //     <div key={0} className="loader p-l-0 p-b-20 text-muted">
      //       {i18n.t('common.label.loading')}
      //     </div>
      //   }
      // >
      <div className="row">
        {/* {tasks.slice(0, this.state.loadTotal).map((item, index) => { */}
        {tasks.map((item, index) => {
          const { action, id, name, description, iconURL = '' } = item;
          return (
            <div className="col-xs-12 col-sm-4 col-md-3" key={`resource_card_index_${index}`}>
              <Card className="card-default card-shadow text-center" key={`resource_card_index_${index}`}>
                {item.isMaintenance && (
                  <div className="card-body">
                    {i18n.t('label.maintenance.resource', {
                      startDate: momentTZ.tz(item.maintenanceStartDate, item.timezone).format(formatDate),
                      endDate: momentTZ.tz(item.maintenanceEndDate, item.timezone).format(formatDate),
                      timezone: item.timezone
                    })}
                  </div>
                )}
                {!item.isMaintenance && (
                  <div className="card-img">
                    <span className="img-helper"></span>
                    <label className="image-item">
                      <img src={iconURL || defaultIcon} alt={description} width="100%" height="100%" />
                    </label>
                  </div>
                )}
                <div className="card-header">
                  <div className="card-title">{name}</div>
                </div>
                {action && action === 2 && !item.isMaintenance && (
                  <div
                    className="access"
                    data-tt-click-send={`${name}+${id}+${userInfo.loginId}`}
                    onClick={() => this.onClick(item)}
                  >
                    <div className="access-link" data-tt-click-send={`${name}+${id}+${userInfo.loginId}`}>
                      {/* <Button data-tt-click-send={`${name}+${id}+${userInfo.loginId}`}>
                        {i18n.t('common.label.access')} <i className="material-icons">launch</i>
                      </Button> */}
                    </div>
                    {/* <div className="mask" data-tt-click-send={`${name}+${id}+${userInfo.loginId}`}></div> */}
                  </div>
                )}
              </Card>
            </div>
          );
        })}
      </div>
      // </InfiniteScroll>
    );
  }
}
