import React from 'react';
import { useTranslation } from 'react-i18next';
import SectionList from 'pages/Section/SectionList';
import SearchBox from 'components/SearchBox';
import { Card, CardHeader, CardBody } from 'reactstrap';

function Services(props) {
  const { t } = useTranslation();
  const {
    resource,
    updateFullLoading,
    updateNotification,
    updateUser,
    handleSearch,
    searchText,
    resourceLists,
    userInfo,
    history
  } = props;

  return (
    <>
      <Card>
        <CardHeader className="card-header-toolbar">
          <div style={{ width: '300px' }} className="float-left m-r-5 m-w-100 m-m-b-10">
            <SearchBox
              placeholder={t('common.placeholder.searchBy', {
                field: t('common.label.fieldName', { field: t('common.label.resource') })
              })}
              value={searchText}
              handleSearch={handleSearch}
            />
          </div>
        </CardHeader>
        <CardBody className="p-b-0">
          <SectionList
            resources={resourceLists}
            updateFullLoading={updateFullLoading}
            updateUser={updateUser}
            updateNotification={updateNotification}
            history={history}
            initialized={resource.resources.initialized}
            userInfo={userInfo}
          />
        </CardBody>
      </Card>
    </>
  );
}

export default Services;
