/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'bookmark',
  initialState: {
    bookmarks: {
      initialized: false,
      data: []
    }
  },
  reducers: {
    addBookmarks(state, { payload }) {
      state.bookmarks = payload;
    },
    updateBookmarks(state, { payload }) {
      state.bookmarks = payload;
    },
    updateBookmarkModal(state, { payload }) {
      const { showModal = false, url = '', displayTitle = '', icon = '', color = '' } = payload;
      state.showModal = showModal;
      state.url = url;
      state.displayTitle = displayTitle;
      state.icon = icon;
      state.color = color;
    }
  }
});

export const { addBookmarks, updateBookmarkModal, updateBookmarks } = slice.actions;
export default slice.reducer;

export const getBookmarks = state => state.bookmark;
