import React, { useEffect, useState } from 'react';
import { Loading } from 'element-react';
import { auth as authService } from 'services';
import { useTranslation } from 'react-i18next';
import '../Login/index.scss';
import { logger } from 'core/logger';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router';
import { encodeBase64, decodeBase64 } from 'core/helpers';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function PresignedURL(props) {
  const { history } = props;
  const queryParams = useQuery();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const handleLoginError = error => {
    let err = error;
    try {
      if (typeof error === 'string') err = JSON.parse(error);
    } catch (exErr) {
      console.error('error: ', error);
    }
    let code = err && err.code;

    logger.error('PresignedURL -> ErrCode', code);
    let warningMessage = '';
    switch (code) {
      case 'ip-address-unauthorized':
        warningMessage = t('url.error.policy.ip.unauthorized');
        break;
      case 'token-expired':
        warningMessage = t('url.error.expired');
        break;
      default:
        warningMessage = t('common.message.error');
        break;
    }
    setLoading(false);
    if (warningMessage) {
      toast.warning(warningMessage);
    }
    setTimeout(() => {
      window.opener = null;
      window.open('about:blank', '_self');
      window.close();
    }, 2500);
  };

  const loginSuccess = data => {
    logger.error('PresignedURL -> data', data);
    const { presignedGETURL } = data;
    logger.error('PresignedURL -> URL', presignedGETURL);
    if (presignedGETURL) {
      // window.location.href = presignedGETURL;
      window.location.replace(presignedGETURL);
    }
    setTimeout(() => {
      window.opener = null;
      window.open('about:blank', '_self');
      window.close();
    }, 2500);
  };

  useEffect(() => {
    const error = queryParams.get('error');
    const authCode = queryParams.get('authCode');

    console.log('Callback info: ', { error, authCode });
    if (error) {
      return handleLoginError(error);
    }

    const token = queryParams.get('token');
    if (token) {
      // Sign In With Google callback from Passwordless
      authService
        .verifyPresignedURL(token)
        .then(loginSuccess)
        .catch(error => {
          console.log('signInGoogle.error: ', error);
          if (error.response && error.response.data && error.response.data.code) {
            handleLoginError(error.response.data);
          } else {
            handleLoginError({ code: error.error });
          }
        });
    }
  }, []);

  return loading && <Loading fullscreen={true} />;
}

export default PresignedURL;
