import React, { useState, useEffect } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Badge } from 'reactstrap';
import classnames from 'classnames';
import Services from './Services';
import Bookmarks from './Bookmarks';
import { bookmark as bookmarkService } from 'services';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { convertTextUtils } from 'core/utils';
import { SERVICE_PROVIDER } from 'core/constants';

function Home(props) {
  const { t } = useTranslation();
  const { resource } = props;

  const [view, setView] = useState('Services');

  /** Service Controller */
  const [searchText, setSearchText] = useState('');
  const [resourceLists, setResourceLists] = useState([]);
  const [bookmarkLists, setBookmarkLists] = useState([]);
  const history = useHistory();

  useEffect(() => {
    filterResourceList(resource.resources.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, resource]);

  const filterResourceList = data => {
    const searchTextInstance = searchText
      ? searchText
          .toString()
          .slice(0)
          .trim()
      : '';
    const fullWidthSeachText = convertTextUtils.convertHalfwidthToFullwidth(searchTextInstance);
    let items = searchTextInstance.length
      ? data.filter(({ name }) => {
          let isMatched =
            name
              .toLowerCase()
              .replace(/\s/g, '')
              .search(searchText.toLowerCase().replace(/\s/g, '')) > -1;

          if (!isMatched && fullWidthSeachText !== searchText) {
            isMatched =
              name
                .toLowerCase()
                .replace(/\s/g, '')
                .search(searchText.toLowerCase().replace(/\s/g, '')) > -1 ||
              name
                .toLowerCase()
                .replace(/\s/g, '')
                .search(fullWidthSeachText.toLowerCase().replace(/\s/g, '')) > -1;
          }

          return isMatched;
        })
      : data;

    const timeSheetList = [],
      previewTimeSheetList = [],
      lysitheaPCList = [],
      previewLysitheaPCList = [],
      lysitheaSPList = [],
      previewLysitheaSPList = [],
      wikiList = [],
      awsList = [],
      otherResourcesList = [];
    items.forEach(item => {
      if (item.serviceProviderType === SERVICE_PROVIDER.TIME_SHEET) {
        if ('PREVIEW' === item.environment) {
          previewTimeSheetList.push(item);
        } else timeSheetList.push(item);
      } else if (item.serviceProviderType === SERVICE_PROVIDER.LYSITHEA_PC) {
        if ('PREVIEW' === item.environment) {
          previewLysitheaPCList.push(item);
        } else lysitheaPCList.push(item);
      } else if (item.serviceProviderType === SERVICE_PROVIDER.LYSITHEA_SP) {
        if ('PREVIEW' === item.environment) {
          previewLysitheaSPList.push(item);
        } else lysitheaSPList.push(item);
      } else if (item.serviceProviderType === SERVICE_PROVIDER.WIKI) wikiList.push(item);
      else if (item.serviceProviderType === SERVICE_PROVIDER.AWS_MNG_CONSOLE) awsList.push(item);
      else otherResourcesList.push(item);
    });

    const sortNameByAlphaBet = arr => {
      return arr.sort((a, b) => a.name.localeCompare(b.name));
    };

    const sortedDatas = [
      ...sortNameByAlphaBet(timeSheetList),
      ...sortNameByAlphaBet(previewTimeSheetList),
      ...sortNameByAlphaBet(lysitheaPCList),
      ...sortNameByAlphaBet(previewLysitheaPCList),
      ...sortNameByAlphaBet(lysitheaSPList),
      ...sortNameByAlphaBet(previewLysitheaSPList),
      ...sortNameByAlphaBet(wikiList),
      ...sortNameByAlphaBet(awsList),
      ...sortNameByAlphaBet(otherResourcesList)
    ];
    setResourceLists(sortedDatas);
  };

  function handleSearch(searchText) {
    setSearchText(searchText);
  }

  const countBookmark = data => setBookmarkLists(data);

  useEffect(() => {
    async function fetchData() {
      try {
        const bookmarkList = await bookmarkService.getBookmarks('');
        setBookmarkLists(bookmarkList || []);
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  return (
    <div>
      <Nav tabs className="nav-tabs-simple">
        {/** Service header */}
        <NavItem>
          <NavLink
            className={classnames({ active: view === 'Services' })}
            onClick={() => {
              setView('Services');
            }}
          >
            {t('menu.home')} <Badge className="ml-1 align-text-bottom">{resourceLists.length}</Badge>
          </NavLink>
        </NavItem>

        {/** Bookmark header */}
        <NavItem>
          <NavLink
            className={classnames({ active: view === 'Bookmarks' })}
            onClick={() => {
              setView('Bookmarks');
            }}
          >
            {t('common.bookmark')} <Badge className="ml-1 align-text-bottom">{bookmarkLists.length}</Badge>
          </NavLink>
        </NavItem>
      </Nav>

      {/** Tab content */}
      <TabContent activeTab={view} className="m-p-lr-0 p-r-20 p-l-20" style={{ overflow: 'visible' }}>
        {/** Services */}
        <TabPane tabId="Services">
          <Row>
            <Col sm="12">
              <Services
                history={history}
                handleSearch={handleSearch}
                searchText={searchText}
                resourceLists={resourceLists}
              />
            </Col>
          </Row>
        </TabPane>

        {/** Bookmark */}
        <TabPane tabId="Bookmarks">
          <Row>
            <Col sm="12">
              <Bookmarks view={view} countBookmark={countBookmark} />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default Home;
