import React, { useEffect, useState, useCallback } from 'react';
import SearchBox from 'components/SearchBox';
import { Tooltip } from 'element-react';
import { debounce } from 'lodash';
import BookmarkList from 'pages/Home/Bookmarks/BookmarkList/view';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { bookmark as bookmarkService } from 'services';
import { errorUtils } from '../../../core/utils/';
import CreateEditBookmarks from './CreateOrEditPopup/view';
import { DEFAULT_COLOR_ICON_SELECT } from 'core/constants';

function Bookmarks(props) {
  const { t } = useTranslation();
  const { view, updateFullLoading, updateNotification, countBookmark, updateUser } = props;
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isPrevBookmarked, setIsPrevBookmarked] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [bookmarks, setBookmarks] = useState([]);
  const [realBookmarkNumber, setRealBookmarkNumber] = useState([]);
  const [mode, setMode] = useState('');
  const [searchText, setSearchText] = useState('');
  const history = useHistory();

  const [bookmark, setBookmark] = useState({
    title: '',
    bookmarkUrl: '',
    icon: '',
    color: '',
    order: ''
  });

  const onChangeValue = (type, value) => {
    let item = { ...bookmark };
    switch (type) {
      case 'title':
        item.title = value;
        break;
      case 'bookmarkUrl':
        item.bookmarkUrl = value;
        break;
      case 'icon':
        item.icon = value;
        break;
      case 'color':
        item.color = value;
        break;
      case 'order':
        item.order = value;
        break;
      default:
        break;
    }
    setBookmark(item);
  };

  const handleBookmarkClick = event => {
    setIsBookmarked(!isBookmarked);
    setIsPrevBookmarked(!isPrevBookmarked);

    const newBookmarks = bookmarks.filter(e => !!e.bookmarkId);
    setBookmarks(newBookmarks);
    event.currentTarget.blur();
  };

  const togglePopup = event => {
    let item = { title: '', bookmarkUrl: '', icon: '', color: DEFAULT_COLOR_ICON_SELECT, order: '' };
    setBookmark(item);
    setIsPopupOpen(!isPopupOpen);
    setMode('Add');
    event.currentTarget.blur();
  };

  const toggleClosePopup = () => {
    let item = { title: '', bookmarkUrl: '', icon: '', color: '', order: '' };
    setBookmark(item);
    setIsPopupOpen(!isPopupOpen);
  };

  const handleError = error => {
    const { code } = error;

    let message = '';

    if (code === 'token-expired' || code === 'invalid-token') {
      const tokenExpiredError = { error: { code: code }, response: { data: { code: code } } };
      errorUtils.handleError(tokenExpiredError, '', updateNotification, updateUser, history, t);
      return;
    }

    switch (code) {
      case 'bookmark-is-existed':
        message = 'bookmark.isExisted';
        break;
      case 'bookmark-not-found':
        message = 'bookmark.notFounded';
        break;
      case 'bookmark-is-reached-limit-exceed':
        message = 'bookmark.limitExceed';
        break;
      case 'bookmark-changed-conflict':
        message = 'bookmark.conflict';
        break;
      default:
        toast.error(t('bookmark.error'));
        return;
    }
    toast.warning(t(message));
  };

  const handleSaveBookmark = async item => {
    try {
      updateFullLoading(true);
      const data = {
        ...item,
        bookmarkUrl: item.bookmarkUrl.trim(),
        title: item.title.trim()
      };

      // Check if title or URL length exceeds the limits
      if (item.title.length > 60) {
        toast.error(t('errors.bookmarkTitleTooLong'));
        return;
      }

      if (item.bookmarkUrl.length > 512) {
        toast.error(t('errors.bookmarkUrlTooLong'));
        return;
      }

      if (mode === 'Add') {
        const bookmark =
          realBookmarkNumber.length > 0
            ? realBookmarkNumber[realBookmarkNumber.length - 1]
            : bookmarks.length && bookmarks[bookmarks.length - 1];

        await bookmarkService.addBookmark({
          ...data,
          order: bookmark ? bookmark.order + 1 : 0
        });
        toast.success(t('create.bookmark.success'));
      } else {
        await bookmarkService.updateBookmark(data.bookmarkId, data);
        toast.success(t('edit.bookmark.success'));
      }

      const curBookmarks = await bookmarkService.getBookmarks('');
      setRealBookmarkNumber(curBookmarks);

      const bookmarkList = await bookmarkService.getBookmarks(searchText);
      setBookmarks(bookmarkList);
      searchText ? countBookmark(bookmarkList || []) : countBookmark(curBookmarks || []);
      toggleClosePopup();
    } catch (err) {
      const { response } = err;
      const { data = {} } = response || {};
      let code = data.code;
      if (err.error) {
        code = err.error;
      }
      if (code === 'user-not-found') {
        toast.warning(t('common.message.user.notFound'));
      } else {
        handleError(data);
      }
    } finally {
      updateFullLoading(false);
    }
  };

  const editBookmark = async bookmarkId => {
    try {
      updateFullLoading(true);
      let item = await bookmarkService.getBookmarkById(bookmarkId);
      setBookmark(item);

      setIsPopupOpen(!isPopupOpen);
      setMode('Edit');
    } catch (err) {
      const { response } = err;
      const { data = {} } = response || {};
      let code = data.code;
      if (err.error) {
        code = err.error;
      }
      if (code === 'user-not-found') {
        toast.warning(t('common.message.user.notFound'));
      } else {
        handleError(data);
      }
    } finally {
      updateFullLoading(false);
    }
  };

  const removeBookmark = async bookmarkId => {
    try {
      updateNotification({
        showModal: true,
        heading: t('bookmark.confirm.header'),
        noCancel: false,
        displayText: t('common.message.confirmAction', {
          action: t('common.button.delete').toLowerCase()
        }),
        type: 'confirm',
        buttonOkText: t('common.button.yes'),
        buttonCloseText: t('common.button.no'),
        callback: async closeModal => {
          closeModal();
          updateFullLoading(true);
          let checkFail = false;
          await bookmarkService.deleteBookmark(bookmarkId).catch(err => {
            const { response } = err;
            const { data = {} } = response || {};
            let code = data.code;
            if (err.error) {
              code = err.error;
            }
            if (code === 'user-not-found') {
              toast.warning(t('common.message.user.notFound'));
            } else {
              handleError(data);
              checkFail = true;
              updateFullLoading(false);
            }
          });

          const bookmarkList = await bookmarkService.getBookmarks(searchText);
          const currbookmarkList = await bookmarkService.getBookmarks('');
          setBookmarks(bookmarkList);
          setRealBookmarkNumber(currbookmarkList);
          searchText ? countBookmark(bookmarkList || []) : countBookmark(currbookmarkList || []);
          updateFullLoading(false);
          if (!checkFail) toast.success(t('remove.bookmark.success'));
        }
      });
    } catch (err) {
      const { response } = err;
      const { data = {} } = response || {};
      let code = data.code;
      if (err.error) {
        code = err.error;
      }
      if (code === 'user-not-found') {
        toast.warning(t('common.message.user.notFound'));
      } else {
        handleError(data);
      }
    } finally {
      updateFullLoading(false);
    }
  };

  const openCreateFromCard = isOpen => {
    if (isOpen) {
      let item = { title: '', bookmarkUrl: '', icon: '', color: DEFAULT_COLOR_ICON_SELECT, order: '' };
      setBookmark(item);
      setIsPopupOpen(!isPopupOpen);
      setMode('Add');
    }
  };

  const updateDragDropPosition = items => {
    for (const [index, bookmark] of items.entries()) {
      bookmark.order = index;
    }
    setBookmarks(items);
  };

  const endDragDrop = async () => {
    try {
      updateFullLoading(true);
      await bookmarkService.dragDropBookmark(bookmarks);
    } catch (err) {
      const { response } = err;
      const { data = {} } = response || {};
      let code = data.code;
      if (err.error) {
        code = err.error;
      }
      if (code === 'user-not-found') {
        toast.warning(t('common.message.user.notFound'));
      } else {
        handleError(data);
      }
    } finally {
      updateFullLoading(false);
    }
  };

  const handleSearchV2 = async text => {
    try {
      updateFullLoading(true);
      const dataCurrent = await bookmarkService.getBookmarks('');
      const data = await bookmarkService.getBookmarks(text);

      setBookmarks(data);
      setRealBookmarkNumber(dataCurrent);
      countBookmark(data || []);
    } catch (err) {
      const { response } = err;
      const { data = {} } = response || {};
      let code = data.code;
      if (err.error) {
        code = err.error;
      }
      if (code === 'user-not-found') {
        toast.warning(t('common.message.user.notFound'));
      } else {
        handleError(data);
      }
    } finally {
      updateFullLoading(false);
    }
  };

  const debouncedAPICall = useCallback(
    debounce(async text => {
      await handleSearchV2(text);
    }, 500),
    []
  );

  const handleSearch = (text, isEnterKey = false) => {
    setSearchText(text);

    if (text.length) {
      setIsBookmarked(false);
    } else {
      setIsBookmarked(isPrevBookmarked);
    }

    if (text !== searchText) {
      if (isEnterKey) {
        handleSearchV2(text);
      } else {
        debouncedAPICall(text);
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (view === 'Bookmarks') {
        try {
          updateFullLoading(true);
          const bookmarkList = await bookmarkService.getBookmarks(searchText);
          countBookmark(bookmarkList || []);
          setBookmarks(bookmarkList || []);
        } catch (err) {
          const { response } = err;
          const { data = {} } = response || {};
          let code = data.code;
          if (err.error) {
            code = err.error;
          }
          if (code === 'user-not-found') {
            toast.warning(t('common.message.user.notFound'));
          } else {
            handleError(data);
          }
        } finally {
          updateFullLoading(false);
        }
      }
    }
    fetchData();
  }, [view]);

  useEffect(() => {
    if (!searchText) countBookmark(realBookmarkNumber || []);
  }, [realBookmarkNumber]);

  return (
    <>
      <Card>
        <CardHeader className="card-header-toolbar d-flex justify-content-between align-items-center">
          <div style={{ width: '300px' }} className="float-left m-r-5 m-w-100 m-m-b-10">
            <SearchBox
              placeholder={t('common.placeholder.searchBy', {
                field: t('bookmark.search.displayTitle.field')
              })}
              value={searchText}
              handleSearch={handleSearch}
            />
          </div>

          <div className="d-flex m-r-5 m-w-100 m-m-b-10">
            {isBookmarked && (
              <span
                style={{
                  marginRight: '10px',
                  borderRadius: '15px',
                  backgroundColor: 'green',
                  color: 'white',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingRight: '12px',
                  paddingLeft: '12px'
                }}
              >
                <b>{t('bookmark.order.enabled')}</b>
              </span>
            )}

            {/** Button bookmark */}
            {bookmarks.length < 20 && realBookmarkNumber.length < 20 && (
              <Tooltip effect="dark" content={t('common.tooltip.addBookmark')} placement="top" className="reload_tip">
                <button className="btn btn-default" type="button" onClick={togglePopup} style={{ marginRight: '10px' }}>
                  <span className="info">
                    <i className="material-icons">bookmark_add</i>
                  </span>
                </button>
              </Tooltip>
            )}

            {/** Button Drag&Drop */}
            <Tooltip
              effect="dark"
              content={<span style={{ whiteSpace: 'nowrap' }}>{t('common.tooltip.bookmark')}</span>}
              placement="top-end"
            >
              <button
                className="btn"
                type="button"
                onClick={handleBookmarkClick}
                disabled={searchText}
                style={{
                  marginRight: '10px',
                  backgroundColor: isBookmarked ? '#5E5E5E' : 'white',
                  color: isBookmarked ? 'white' : 'black',
                  border: '1px solid #ddd'
                }}
              >
                {
                  <span className="info">
                    <i className="material-icons">swap_vert</i>
                  </span>
                }
              </button>
            </Tooltip>
          </div>
        </CardHeader>

        <CardBody className="p-b-0">
          <BookmarkList
            bookmarks={bookmarks}
            curBookmarks={realBookmarkNumber}
            updateFullLoading={updateFullLoading}
            updateNotification={updateNotification}
            history={history}
            isEditBookmark={isBookmarked}
            editBookmark={editBookmark}
            removeBookmark={removeBookmark}
            openCreateFromCard={openCreateFromCard}
            setBookmarkList={updateDragDropPosition}
            endDragDrop={endDragDrop}
          />
        </CardBody>
      </Card>

      {isPopupOpen && (
        <CreateEditBookmarks
          mode={mode}
          bookmark={bookmark}
          isPopupOpen={isPopupOpen}
          toggleClosePopup={toggleClosePopup}
          handleSaveBookmark={handleSaveBookmark}
          onChangeValue={onChangeValue}
        />
      )}
    </>
  );
}

export default Bookmarks;
