import { connect } from 'react-redux';
import view from 'pages/Login/view';
import callbackView from './callback';
import presignedURL from './presignedURL';
import { updateNotification, selectNotification } from 'store/notificationSlice';
import { updateUser } from 'store/userSlice';
import { updateFullLoading, selectFullLoading } from 'store/loadingSlice';
import { setModeLogin, getModeLogin, getLoginIdStore, setLoginIdStore } from 'store/globalSlice';

const mapState = state => {
  return {
    notification: { ...selectNotification(state) },
    fullloading: selectFullLoading(state),
    modeLogin: getModeLogin(state),
    loginIdStore: getLoginIdStore(state)
  };
};

const mapDispatch = {
  updateNotification,
  updateUser,
  updateFullLoading,
  setModeLogin,
  setLoginIdStore
};

export default connect(mapState, mapDispatch)(view);

const Callback = connect(mapState, mapDispatch)(callbackView);
const PresignedURL = connect(mapState, mapDispatch)(presignedURL);
export { Callback, PresignedURL };
