import { connect } from 'react-redux';
import view from 'pages/Home/Services/view';
import { updateNotification, selectNotification } from 'store/notificationSlice';
import { getResource, addResources } from 'store/resourceSlice';
import { updateFullLoading } from 'store/loadingSlice';
import { updateUser, getUser } from 'store/userSlice';
import { getSections } from 'store/sectionSlice';

const mapState = state => {
  return {
    notification: { ...selectNotification(state) },
    resource: getResource(state),
    sections: getSections(state),
    userInfo: getUser(state)
  };
};

const mapDispatch = {
  updateNotification: updateNotification,
  updateFullLoading: updateFullLoading,
  updateUser: updateUser,
  addResources
};

export default connect(mapState, mapDispatch)(view);
