import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'assets/scss/theme/termsOfUse.scss';

class Paragraph extends Component {
  static propTypes = { content: PropTypes.string };
  static defaultProps = { content: '' };
  render = () => <p className={''} dangerouslySetInnerHTML={{ __html: this.props.content }} />;
}

function TermsOfUse() {
  const { t } = useTranslation();
  const lang = localStorage.getItem('sysLanguage') || 'en_US';
  return (
    <div className="terms_of_use-wrapper">
      <div className="container">
        <div className="row">
          <h2 className="title-page">{t('termsOfUse.title')}</h2>
          <div className={`content content_${lang}`}>
            <Paragraph content={t('termsOfUse.content.intro.part.1') + t('termsOfUse.content.intro.part.2')} />
            <Paragraph
              content={
                t('termsOfUse.content.article.1.part.1') +
                t('termsOfUse.content.article.1.part.2') +
                t('termsOfUse.content.article.1.part.3')
              }
            />
            <Paragraph content={t('termsOfUse.content.article.2.part.1') + t('termsOfUse.content.article.2.part.2')} />
            <Paragraph
              content={
                t('termsOfUse.content.article.3.part.1') +
                t('termsOfUse.content.article.3.part.2') +
                t('termsOfUse.content.article.3.part.3') +
                t('termsOfUse.content.article.3.part.4') +
                t('termsOfUse.content.article.3.part.5')
              }
            />
            <Paragraph content={t('termsOfUse.content.article.4')} />
            <Paragraph
              content={
                t('termsOfUse.content.article.5.part.1') +
                t('termsOfUse.content.article.5.part.2') +
                t('termsOfUse.content.article.5.part.3')
              }
            />
            <Paragraph content={t('termsOfUse.content.article.6')} />
            <Paragraph content={t('termsOfUse.content.article.7')} />
            <Paragraph content={t('termsOfUse.content.article.8')} />
            <Paragraph content={t('termsOfUse.content.established')} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default TermsOfUse;
