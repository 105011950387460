import React, { PureComponent } from 'react';
import { Card, Button } from 'reactstrap';

import './index.scss';

function CardItem(props) {
  const onClick = item => {};

  const { isEditBookmark, item, index } = props;
  const { icon } = item;
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-4 col-md-3" key={`resource_card_index_${index}`}>
        <Card
          className={`card-default card-shadow text-center ${isEditBookmark ? 'disable-hover' : ''}`}
          key={`resource_card_index_${index}`}
        >
          <div className="card-body"></div>
          <div className="card-img" onClick={() => onClick(item)}>
            <span className="img-helper"></span>
            <label className="image-item">
              <span>
                <i
                  className="material-icons"
                  style={{
                    fontSize: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    color: 'red'
                  }}
                >
                  <b>{icon}</b>
                </i>
              </span>
            </label>
          </div>

          <div className="card-header">
            <div className="card-title">{''}</div>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default CardItem;
